<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Choices from "choices.js";

/**
 * User settings component
 */
export default {
  page: {
    title: "User Settings",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Settings",
      items: [
        {
          text: "Contacts",
          href: "/",
        },
        {
          text: "Settings",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
  mounted() {
    new Choices("#choices-multiple-skill-input", {
      removeItemButton: true,
    });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xxl-3 col-lg-4">
        <div class="card">
          <div class="card-body">
            <div
              class="profile-bg position-relative overflow-hidden rounded-top"
              style="height: 150px;"
            >
              <div class="bg-overlay bg-dark bg-gradient"></div>

              <div class="position-relative p-3 text-end">
                <button type="button" class="btn btn-primary btn-sm">
                  <i class="mdi mdi-pencil me-1"></i> Edit
                </button>
              </div>
            </div>
            <div class="text-center">
              <div class="position-relative d-inline-block mx-auto mt-n5 mb-4">
                <div class="avatar-lg">
                  <img
                    src="@/assets/images/users/avatar-1.jpg"
                    alt=""
                    class="rounded-circle img-thumbnail"
                  />
                </div>
                <a href="#" class="d-block position-absolute bottom-0 end-0">
                  <div class="avatar-sm">
                    <div
                      class="avatar-title rounded-circle bg-light text-primary"
                    >
                      <i class="mdi mdi-pencil"></i>
                    </div>
                  </div>
                </a>
              </div>

              <h5 class="font-size-16 mb-1">Katie Sharpe</h5>
              <p class="text-muted mb-0">Full Stack Developer</p>
            </div>
            <hr class="my-4" />
            <div>
              <h5 class="font-size-16">Info :</h5>

              <div class="mt-4">
                <p class="text-muted mb-1">Name :</p>
                <h5 class="font-size-14 text-truncate">Katie Sharpe</h5>
              </div>

              <div class="mt-4">
                <p class="text-muted mb-1">E-mail :</p>
                <h5 class="font-size-14 text-truncate">katie@dashonic.com</h5>
              </div>

              <div class="mt-4">
                <p class="text-muted mb-1">Location :</p>
                <h5 class="font-size-14 text-truncate">
                  California, United States
                </h5>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-xxl-9 col-lg-8">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-4">Setting</h5>
            <form>
              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">General Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input"
                            >Name</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="gen-info-name-input"
                            placeholder="Enter Name"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label
                            class="form-label"
                            for="gen-info-designation-input"
                            >Designation</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            id="gen-info-designation-input"
                            placeholder="Enter Designation"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mb-3">
                      <label class="form-label" for="gen-info-description-input"
                        >Description</label
                      >
                      <textarea
                        class="form-control"
                        placeholder="Enter Description"
                        id="gen-info-description-input"
                        rows="3"
                      ></textarea>
                    </div>

                    <div
                      class="form-check mb-3"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseChangePassword"
                      aria-expanded="false"
                      aria-controls="collapseChangePassword"
                    >
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="gen-info-change-password"
                      />
                      <label
                        class="form-check-label"
                        for="gen-info-change-password"
                        >Change passowrd?</label
                      >
                    </div>

                    <div class="collapse" id="collapseChangePassword">
                      <div class="card border shadow-none card-body">
                        <div class="row">
                          <div class="col-lg-4">
                            <div class="mb-lg-0">
                              <label
                                for="current-password-input"
                                class="form-label"
                                >Current password</label
                              >
                              <input
                                type="password"
                                class="form-control"
                                placeholder="Enter Current password"
                                id="current-password-input"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="mb-lg-0">
                              <label for="new-password-input" class="form-label"
                                >New password</label
                              >
                              <input
                                type="password"
                                class="form-control"
                                placeholder="Enter New password"
                                id="new-password-input"
                              />
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <div class="mb-lg-0">
                              <label
                                for="confirm-password-input"
                                class="form-label"
                                >Confirm password</label
                              >
                              <input
                                type="password"
                                class="form-control"
                                placeholder="Enter Confirm password"
                                id="confirm-password-input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <label
                        for="choices-multiple-skill-input"
                        class="form-label"
                        >Skills</label
                      >
                      <select
                        class="form-control"
                        name="choices-multiple-skill-input"
                        id="choices-multiple-skill-input"
                        multiple
                      >
                        <option value="Photoshop" selected>Photoshop</option>
                        <option value="illustrator" selected
                          >illustrator</option
                        >
                        <option value="HTML">HTML</option>
                        <option value="CSS">CSS</option>
                        <option value="Javascript">Javascript</option>
                        <option value="Php">Php</option>
                        <option value="Python">Python</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end card -->

              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        02
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Contact Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div class="mb-3">
                    <label for="contact-info-email-input">Email :</label>
                    <input
                      type="email"
                      class="form-control"
                      id="contact-info-email-input"
                      placeholder="Enter Email"
                    />
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-md-0">
                        <label
                          for="contact-info-website-input"
                          class="form-label"
                          >Website</label
                        >
                        <input
                          type="url"
                          class="form-control"
                          placeholder="Enter website url"
                          id="contact-info-website-input"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-md-0">
                        <label
                          for="contact-info-location-input"
                          class="form-label"
                          >Location</label
                        >
                        <input
                          type="url"
                          class="form-control"
                          placeholder="Enter Address"
                          id="contact-info-location-input"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end card -->

              <div class="card border shadow-none">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        03
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Experience</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="workexperience-designation-input"
                          >Designation</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="workexperience-designation-input"
                          placeholder="Enter Designation title"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="workexperience-companyname-input"
                          >Company name</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="workexperience-companyname-input"
                          placeholder="Enter Company name"
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <label>Years</label>
                    <input
                      type="text"
                      class="form-control"
                      id="datepicker-range"
                    />
                  </div>
                </div>
              </div>
              <!-- end card -->

              <div class="text-end">
                <button type="submit" class="btn btn-primary w-sm">
                  Submit
                </button>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
@import "~choices.js/public/assets/styles/choices.min.css";
</style>
